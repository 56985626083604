import React, { Component } from 'react';
import { Navbar, Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { FiList } from "react-icons/fi";
import NavigationBar from "components/navigation/top";
import NavigationView from "components/navigation/left";
import DashboardEditor from "./editor";
import "./index.css";

import CommonEmptyWidget from "widgets/common/empty";
import TradingViewMiniChartWidget from "widgets/tradingView/miniChart";
import TradingViewEconomicCalendarWidget from "widgets/tradingView/economicCalendar";
import TradingViewStockMarketWidget from "widgets/tradingView/stockMarket";
import TradingViewMarketOverviewWidget from "widgets/tradingView/marketOverview";
import TradingViewMarketDataWidget from "widgets/tradingView/marketData";
import TradingViewRealtimeWidget from "widgets/tradingView/realtime";

export default class DashboardView extends Component {
  toggleNavigation = () => {
    this.props.main.toggleNavigation();
  }

  render() {
    return (
      <>
        <NavigationBar
          main={this.props.main}
          glance={this.props.main.glance}
        />

        <Container fluid className="dashboard-content">
          <Row>
            {this.props.main.showNavigation &&
              <Col sm="2" className="dashboard-cell">
                <NavigationView
                  glances={this.props.glances}
                  main={this.props.main}
                />
              </Col>
            }
            <Col sm={this.props.main.showNavigation ? 10 : 12} className="dashboard-cell">
              {this.props.main.showEditing &&
                <DashboardEditor
                  main={this.props.main}
                  glance={this.props.main.glance}
                />
              }

              {!this.props.main.showEditing &&
                <Container fluid>
                  {this.props.glance.rows.map((row, rowIndex) => {
                    return (
                      <Row className="dashboard-row" style={{height: row.view.height}}>
                          {row.cells.map((cell, cellIndex) => {
                            return (
                            <Col sm={cell.view.width} className="dashboard-cell">
                              {"common_empty" == cell.widget.type &&
                                <CommonEmptyWidget widget={cell.widget} />
                              }
                              {"tradingview_mini_chart" == cell.widget.type &&
                                <TradingViewMiniChartWidget widget={cell.widget} />
                              }
                              {"tradingview_economic_calendar" == cell.widget.type &&
                                <TradingViewEconomicCalendarWidget widget={cell.widget} />
                              }
                              {"tradingview_stock_market" == cell.widget.type &&
                                <TradingViewStockMarketWidget widget={cell.widget} />
                              }
                              {"tradingview_market_overview" == cell.widget.type &&
                                <TradingViewMarketOverviewWidget widget={cell.widget} />
                              }
                              {"tradingview_market_data" == cell.widget.type &&
                                <TradingViewMarketDataWidget widget={cell.widget} />
                              }
                              {"tradingview_realtime" == cell.widget.type &&
                                <TradingViewRealtimeWidget widget={cell.widget} />
                              }
                            </Col>
                          );
                        })}
                      </Row>
                    )
                  })}
                </Container>
              }
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
