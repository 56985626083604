import React, { Component } from 'react';
import { Navbar, Button, Container, Row, Col } from 'react-bootstrap';
import { FiList } from "react-icons/fi";
import NavigationBar from "components/navigation/top";
import NavigationView from "components/navigation/left";
import DashboardView from "components/dashboard/index";
import FooterView from "components/footer/index";
import axios from "axios";

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNavigation: false,
      toggleNavigation: this.toggleNavigation,
      showEditing: props.editing ? true : false,
      toggleEditing: this.toggleEditing,
      glances: null,
      glance: null,
      setGlance: this.setGlance
    };
  }

  toggleNavigation = () => {
    this.setState({showNavigation: !this.state.showNavigation});
  }

  setGlance = (glance) => {
    this.setState({glance: glance});
    this.saveGlances();
  }

  loadGlances() {
    const localGlancesData = localStorage.getItem("glances");
    if ( localGlancesData ) {
      const glances = JSON.parse(localGlancesData);
      this.finishLoadingGlances(glances);
    } else {
      axios.get("/api/glances/get").then( response => {
        const glances = response.data;
        this.finishLoadingGlances(glances);
        this.saveGlances();
      });
    }
  }

  finishLoadingGlances(glances) {
    let currentGlance = null;
    for ( let glance of glances ) {
      if ( glance.id == this.props.match.params.glanceId ) {
        currentGlance = glance;
        break;
      }
    }
    this.setState({
      glances: glances,
      glance: currentGlance
    });
  }

  saveGlances() {
    const localGlancesData = JSON.stringify(this.state.glances);
    localStorage.setItem("glances", localGlancesData);
  }

  componentWillMount() {
    this.loadGlances();
  }

  render() {
    return (
      <>
        {this.state.glances &&
          <>
            {this.state.glance &&
              <DashboardView
                main={this.state}
                glances={this.state.glances}
                glance={this.state.glance}
              />
            }
            {!this.state.glance &&
              <>
                <NavigationBar
                  main={this.state}
                />

                <Container fluid className="dashboard-content">
                  <Row>
                    {this.state.showNavigation &&
                      <Col sm="2" className="dashboard-cell">
                        <NavigationView
                          glances={this.state.glances}
                        />
                      </Col>
                    }
                    <Col sm={this.state.showNavigation ? 10 : 12} className="dashboard-cell">
                      <Container fluid className="text-center">
                        Select existing Glance or create a new one.
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </>
            }
          </>
        }
        {!this.state.glances &&
          <>
            <NavigationBar
              main={this.state}
            />

            <Container fluid className="dashboard-content">
              <Row>
                <Col sm="12" className="dashboard-cell">
                  <Container fluid className="text-center">
                    Loading your Glances..
                  </Container>
                </Col>
              </Row>
            </Container>
          </>
        }

        <FooterView/>
      </>
    );
  }
}
