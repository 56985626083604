import React, { Component } from 'react';
import "./empty.css";

export default class Widget extends Component {
  render() {
    return (
      <>
        <div className="widget-common-empty-cell">&nbsp;</div>
      </>
    );
  }
}
