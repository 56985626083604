import React, { Component } from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import { FiPlus, FiTrash2, FiEdit } from "react-icons/fi";
import { GoX } from "react-icons/go";
import "./index.css";

export default class NavigationView extends Component {
  render() {
    return (
      <>
        <Card>
          <Card.Header>Glances</Card.Header>
          <ListGroup variant="flush">
            {this.props.glances.map((glance, index) => {
              return (
                <ListGroup.Item>
                  <a href={"/" + glance.id}>{glance.name}</a>
                  <span className="float-right">
                    <Button variant="light" className="btn-mini-action" disabled><FiEdit/></Button>
                    <Button variant="light" className="btn-mini-action" disabled><FiTrash2/></Button>
                  </span>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <Card.Body className="text-center">
            <Button variant="success" size="sm" disabled>Create New Glance</Button>
          </Card.Body>
        </Card>
      </>
    );
  }
}
