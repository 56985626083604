import React, { Component } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { FiList, FiEdit, FiCheckSquare } from "react-icons/fi";

export default class NavigationBar extends Component {
  toggleNavigation = () => {
    this.props.main.toggleNavigation();
  }

  render() {
    return (
      <>
        <Navbar bg="light" variant="light" expand="lg" className="shadow-sm">
          <Navbar.Brand>
            <Button variant="light" className="btn-mini-action" onClick={this.toggleNavigation}><FiList/></Button>
            &nbsp;&nbsp;
            <a href="/">Glances</a>
          </Navbar.Brand>
          {this.props.glance &&
            <>
              <Nav>
                {this.props.glance.name}
                &nbsp;&nbsp;
              </Nav>
              <Nav>
                {this.props.main.showEditing ?
                  <a href={"/" + this.props.glance.id}>
                    <FiCheckSquare/>
                  </a> :
                  <a href={"/" + this.props.glance.id + "/edit"}>
                    <FiEdit/>
                  </a>
                }
              </Nav>
            </>
          }
        </Navbar>
      </>
    );
  }
}
