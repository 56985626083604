import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, Card, ListGroup } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { FiPlus, FiArrowLeft, FiArrowRight, FiX, FiArrowUp, FiArrowDown, FiColumns } from "react-icons/fi";

export default class DashboardEditor extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * Rows
   */

  createNewRow() {
    let row = {
      "view": {
        "height": "400px"
      },
      "cells": []
    };
    return row;
  }

  addFirstRow() {
    let row = this.createNewRow();
    this.props.glance.rows.push(row);
    this.props.main.setGlance(this.props.glance);
  }

  addRowAbove(rowIndex) {
    let row = this.createNewRow();
    this.props.glance.rows.splice(rowIndex, 0, row);
    this.props.main.setGlance(this.props.glance);
  }

  addRowBelow(rowIndex) {
    let row = this.createNewRow();
    this.props.glance.rows.splice(rowIndex + 1, 0, row);
    this.props.main.setGlance(this.props.glance);
  }

  setRowHeight(row, height) {
    row.view.height = height;
    this.props.main.setGlance(this.props.glance);
  }

  removeRow(rowIndex) {
    this.props.glance.rows.splice(rowIndex, 1);
    this.props.main.setGlance(this.props.glance);
  }

  /**
   * Cells
   */

  createNewCell() {
    // TODO: Hardcoding realtime TV widget
    let cell = {
      "view": {
        "width": 1
      },
      "widget": {
        "type": "tradingview_realtime",
        "symbol": ""
      }
    };
    return cell;
  }

  addFirstCell(rowIndex) {
    let cell = this.createNewCell();
    this.props.glance.rows[rowIndex].cells.push(cell);
    this.props.main.setGlance(this.props.glance);
  }

  addCellToTheLeft(rowIndex, cellIndex) {
    let cell = this.createNewCell();
    this.props.glance.rows[rowIndex].cells.splice(cellIndex, 0, cell);
    this.props.main.setGlance(this.props.glance);
  }

  addCellToTheRight(rowIndex, cellIndex) {
    let cell = this.createNewCell();
    this.props.glance.rows[rowIndex].cells.splice(cellIndex + 1, 0, cell);
    this.props.main.setGlance(this.props.glance);
  }

  setCellWidth(cell, width) {
    cell.view.width = width;
    this.props.main.setGlance(this.props.glance);
  }

  setCellWidgetProperty(cell, key, value) {
    cell.widget[key] = value;
    this.props.main.setGlance(this.props.glance);
  }

  removeCell(rowIndex, cellIndex) {
    this.props.glance.rows[rowIndex].cells.splice(cellIndex, 1);
    this.props.main.setGlance(this.props.glance);
  }

  /**
   * Rendering
   */

  render() {
    return (
      <Container>
        {this.props.glance.rows &&
          <>
            <br/>
            {this.props.glance.rows.map((row, rowIndex) => {
              return (
                <>
                  <Row>
                    <Col sm="12">
                      <Card>
                        <Card.Body>
                          <Card.Title>Row</Card.Title>
                          <Row>
                            <Col sm="10">
                              <Row>
                                {row.cells.map((cell, cellIndex) => {
                                  return (
                                    <Col sm="3" className="cell-controls">
                                      <Card>
                                        <ListGroup>
                                          <ListGroup.Item>
                                            Cell Controls
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <Button variant="light" size="sm"
                                              onClick={() => this.addCellToTheLeft(rowIndex, cellIndex)}>
                                              <FiArrowLeft/> <FiPlus/>
                                            </Button> &nbsp;
                                            <Button variant="light" size="sm"
                                              onClick={() => this.addCellToTheRight(rowIndex, cellIndex)}>
                                              <FiPlus/> <FiArrowRight/>
                                            </Button> &nbsp;
                                            <Button variant="light" size="sm"
                                              onClick={() => this.removeCell(rowIndex, cellIndex)}>
                                              <FiX/>
                                            </Button>
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <InputGroup size="sm">
                                              <div class="input-group-prepend">
                                                <InputGroup.Text>Width</InputGroup.Text>
                                              </div>
                                              <Form.Control
                                                type="text" placeholder="Width"
                                                value={cell.view.width}
                                                onChange={(event) => this.setCellWidth(cell, event.target.value)}
                                              />
                                            </InputGroup>
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            {Object.keys(cell.widget).map(key =>
                                              <p class="cell-widget-property">
                                                <InputGroup size="sm">
                                                  <div class="input-group-prepend">
                                                    <InputGroup.Text>{key}</InputGroup.Text>
                                                  </div>
                                                  <Form.Control
                                                    type="text" placeholder={key}
                                                    value={cell.widget[key]}
                                                    onChange={(event) => this.setCellWidgetProperty(cell, key, event.target.value)}
                                                  />
                                                </InputGroup>
                                              </p>
                                            )}
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Card>
                                    </Col>
                                  );
                                })}
                                {0 == row.cells.length &&
                                  <Col sm="12">
                                    <Card>
                                      <Card.Body>
                                        <Card.Title>Empty Row</Card.Title>
                                        <Card.Text>
                                          No cells are currently present. Start by adding the first one.
                                        </Card.Text>
                                        <Button variant="light" size="sm" onClick={() => this.addFirstCell(rowIndex)}>
                                          <FiPlus/> <FiColumns/>
                                        </Button>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col sm="2">
                              <Card>
                                <ListGroup>
                                  <ListGroup.Item>
                                    Row Controls
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <Button variant="light" size="sm" onClick={() => this.addRowAbove(rowIndex)}>
                                      <FiArrowUp/> <FiPlus/>
                                    </Button> &nbsp;
                                    <Button variant="light" size="sm" onClick={() => this.addRowBelow(rowIndex)}>
                                      <FiArrowDown/> <FiPlus/>
                                    </Button>
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <InputGroup size="sm">
                                      <div class="input-group-prepend">
                                        <InputGroup.Text>Height</InputGroup.Text>
                                      </div>
                                      <Form.Control
                                        type="text" placeholder="Height"
                                        value={row.view.height}
                                        onChange={(event) => this.setRowHeight(row, event.target.value)}
                                      />
                                    </InputGroup>
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <Button variant="light" size="sm" onClick={() => this.removeRow(rowIndex)}>
                                      <FiX/> Entire Row
                                    </Button>
                                  </ListGroup.Item>
                                </ListGroup>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <br/>
                </>
              );
            })}
          </>
        }
        {0 == this.props.glance.rows.length &&
          <>
            <Row>
              <Col sm="12">
                <Card>
                  <Card.Body>
                    <Card.Title>Empty Glance</Card.Title>
                    <Card.Text>
                      No rows are currently present. Start by adding the first one.
                    </Card.Text>
                    <Button variant="light" size="sm" onClick={() => this.addFirstRow()}>
                      <FiPlus/> Row
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        }
      </Container>
    );
  }
}
