import React, { Component } from 'react';
import { Container, Alert } from 'react-bootstrap';
import './index.css'

export default class Footer extends Component {
  render() {
    return (
      <>
        <div className="navbar fixed-bottom footer">
          © {(new Date().getFullYear())} Glances &nbsp;&nbsp;
          <span className="float-right">
            <Alert variant="warning" className="construction">
              Prototype
            </Alert>
          </span>
        </div>
      </>
    );
  }
}
