import React, { Component, useId } from 'react';

let lastId = 0;

export default class Widget extends Component {
  constructor(props) {
    super(props);
    this.id = "tv_realtime_" + lastId;
    ++lastId;
  }

  componentDidMount() {
    new window.TradingView.widget({
      width: "100%",
      height: "100%",
      symbol: this.props.widget.symbol,
      range: "1D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      allow_symbol_change: true,
      container_id: this.id
    });
  }
s
  render() {
    return (
      <div className='tradingview-widget-container'>
        <div id={this.id} />
      </div>
    );
  }
}
