import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainPage from "pages/main/index";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact path="/"
          render={(props) => <MainPage {...props}/>}
        />
        <Route
          exact path="/:glanceId"
          render={(props) => <MainPage {...props}/>}
        />
        <Route
          exact path="/:glanceId/edit"
          render={(props) => {
            props.editing = true;
            return <MainPage {...props}/>;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
