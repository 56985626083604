import React, { Component } from 'react';

export default class Widget extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js"
    script.async = true;
    script.innerHTML = JSON.stringify({
      "colorTheme": "light",
      "isTransparent": false,
      "width": "100%",
      "height": "100%",
      "locale": "en",
      "importanceFilter": "-1,0,1",
      "currencyFilter": "USD"
    });
    this.ref.current.appendChild(script);
  }

  render() {
    return (
      <>
        <div class="tradingview-widget-container" ref={this.ref}>
          <div class="tradingview-widget-container__widget"></div>
        </div>
      </>
    );
  }
}
